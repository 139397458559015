import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Select,
    MenuItem,
    ButtonGroup,
    InputLabel,
    FormControl,
    Radio,
    RadioGroup,
    FormLabel,
    Button,
    Tabs,
    Tab,
    TextField,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import useResponsive from '../hooks/useResponsive';

const ScheduleDialog = ({ open, onClose, onSave, currentDateTime, currentVenue }) => {
    const [venue, setVenue] = React.useState(currentVenue?.replace('Court ', '') || '');
    const [dateTime, setDateTime] = React.useState(currentDateTime || null);

    const handleSave = () => {
        onSave({
            venue: venue ? `Court ${venue}` : null,
            dateTime: dateTime ? dateTime.format('YYYY-MM-DD HH:mm') : null  // Format the date before saving
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Schedule Match</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DateTimePicker
                            label="Date & Time"
                            value={dateTime}
                            onChange={setDateTime}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <Select
                        value={venue}
                        onChange={(e) => setVenue(e.target.value)}
                        label="Court"
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8].map(court => (
                            <MenuItem key={court} value={court}>Court {court}</MenuItem>
                        ))}
                    </Select>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

const ResultDialog = ({ open, onClose, onSave, match }) => {
    const [player1Score, setPlayer1Score] = React.useState('');
    const [player2Score, setPlayer2Score] = React.useState('');
    const [winner, setWinner] = React.useState('');
    const [resultType, setResultType] = React.useState('normal'); // new state for result type

    React.useEffect(() => {
        if (match) {
            setPlayer1Score(match.player1?.score?.[0] || '');
            setPlayer2Score(match.player2?.score?.[0] || '');
            setWinner('');
            setResultType('normal');

            // If one player is BYE, automatically set result type to BYE
            if (match.player1.name === 'BYE' || match.player2.name === 'BYE') {
                setResultType('bye');
                // Automatically set the non-BYE player as winner
                setWinner(match.player1.name === 'BYE' ? 'player2' : 'player1');
            }
        }
    }, [match]);

    if (!match) return null;

    const handleSave = () => {
        const result = {
            player1: {
                ...match.player1,
                score: resultType === 'normal' ? [parseInt(player1Score, 10)] : [],
                winner: winner === 'player1',
                walkover: resultType === 'walkover' && winner === 'player2',
                bye: resultType === 'bye' && match.player1.name === 'BYE'
            },
            player2: {
                ...match.player2,
                score: resultType === 'normal' ? [parseInt(player2Score, 10)] : [],
                winner: winner === 'player2',
                walkover: resultType === 'walkover' && winner === 'player1',
                bye: resultType === 'bye' && match.player2.name === 'BYE'
            }
        };
        onSave(result);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            // Add consistent sizing and mobile-friendly settings
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '400px', // Maximum width on larger screens
                    minHeight: '400px', // Minimum height to prevent content jumps
                    margin: '16px', // Ensure some spacing on very small screens
                }
            }}
        >
            <DialogTitle>Enter Match Result</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // Add minimum height to DialogContent to prevent size changes
                    minHeight: '280px',
                    // Add padding to prevent content from touching the edges on mobile
                    p: { xs: 2, sm: 3 },
                    '& .MuiFormControl-root': {
                        width: '100%', // Make form controls take full width
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        flex: 1, // Take up available space
                    }}
                >
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Result Type</FormLabel>
                        <RadioGroup
                            value={resultType}
                            onChange={(e) => setResultType(e.target.value)}
                            sx={{ mb: 2 }}
                        >
                            <FormControlLabel
                                value="normal"
                                control={<Radio />}
                                label="Normal Match"
                                disabled={match.player1.name === 'BYE' || match.player2.name === 'BYE'}
                            />
                            <FormControlLabel
                                value="walkover"
                                control={<Radio />}
                                label="Walkover"
                                disabled={match.player1.name === 'BYE' || match.player2.name === 'BYE'}
                            />
                            <FormControlLabel
                                value="bye"
                                control={<Radio />}
                                label="BYE"
                                disabled={match.player1.name !== 'BYE' && match.player2.name !== 'BYE'}
                            />
                        </RadioGroup>
                    </FormControl>

                    {resultType === 'normal' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField
                                label={`${match.player1.name}'s Score`}
                                value={player1Score}
                                onChange={(e) => setPlayer1Score(e.target.value)}
                                type="number"
                                fullWidth
                                size="medium"
                            />
                            <TextField
                                label={`${match.player2.name}'s Score`}
                                value={player2Score}
                                onChange={(e) => setPlayer2Score(e.target.value)}
                                type="number"
                                fullWidth
                                size="medium"
                            />
                        </Box>
                    )}

                    <FormControl fullWidth>
                        <InputLabel>Winner</InputLabel>
                        <Select
                            value={winner}
                            onChange={(e) => setWinner(e.target.value)}
                            label="Winner"
                            disabled={resultType === 'bye'}
                            sx={{ mt: 1 }}
                        >
                            <MenuItem value="player1">{match.player1.name}</MenuItem>
                            <MenuItem value="player2">{match.player2.name}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};


const BracketContainer = styled('div')({
    display: 'flex',
    padding: '20px',
    gap: '40px',
    overflowX: 'auto',
    backgroundColor: '#fff'
});

const RoundSwitcher = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '16px',
}));

const RoundButton = styled(Button)(({ selected }) => ({
    minWidth: '48px',
    height: '48px',
    borderRadius: '50%',
    padding: 0,
    backgroundColor: selected ? '#1976d2' : '#fff',
    color: selected ? '#fff' : '#000',
    border: '1px solid #e0e0e0',
    '&:hover': {
        backgroundColor: selected ? '#1565c0' : '#f5f5f5',
    },
}));

const Round = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    minWidth: '300px',
    '& h2': {
        margin: '0 0 16px 0',
        fontSize: '16px',
        fontWeight: 500,
    }
});

const RoundTitle = styled(Typography)({
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '16px',
    paddingBottom: '8px',
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
    '&.active': {
        color: '#1976d2',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: -1,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: '#1976d2',
        }
    }
});

const Match = styled(Card)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    backgroundColor: '#f8f9fa',
    boxShadow: 'none',
    borderRadius: '4px',
    '&::after': {
        content: '""',
        position: 'absolute',
        right: '-40px',
        top: '50%',
        width: '40px',
        height: '2px',
        backgroundColor: '#e0e0e0',
    },
    '&:last-child::after': {
        display: 'none'
    }
}));

const MatchContent = styled(CardContent)({
    padding: '8px !important',
});

const Player = styled('div')(({ winner }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    position: 'relative',
    '& > span:first-of-type': {
        fontWeight: winner ? 500 : 400,
    }
}));

const Score = styled('div')({
    display: 'flex',
    gap: '4px',
    alignItems: 'center'
});

const ScoreNumber = styled('span')({
    minWidth: '16px',
    textAlign: 'center',
});

const Seed = styled('span')({
    color: '#666',
    fontSize: '0.9em',
    marginLeft: '4px',
});

const DateTime = styled('div')({
    fontSize: '0.85em',
    color: '#666',
    marginBottom: '8px',
    backgroundColor: '#eceef0',
    padding: '8px',
    borderRadius: '4px',
});

const Location = styled('span')({
    fontSize: '0.85em',
    color: '#666',
    float: 'right',
});

const WinnerCheck = styled(CheckIcon)({
    color: '#2e7d32',
    fontSize: '16px',
    marginRight: '4px',
});

// Helper function to calculate the midpoint offset for a match
const calculateMatchOffset = (roundIndex, matchIndex, totalMatches) => {
    const MATCH_HEIGHT = 140; // Height of a single match card
    const MATCH_SPACING = 30; // Base spacing between matches in the same round

    if (roundIndex === 0) return 0;

    // For semifinals (round 1)
    if (roundIndex === 1) {
        if (matchIndex === 0) {
            return MATCH_HEIGHT / 2
        }
        // Calculate the midpoint between two consecutive quarterfinal matches
        return MATCH_HEIGHT + (MATCH_SPACING);
    }

    // For finals (round 2)
    if (roundIndex === 2) {
        if (matchIndex === 0) {
            return (MATCH_HEIGHT) * 1.5 + (MATCH_SPACING) * 1;
        }
        // Calculate the midpoint between two semifinal matches
        return (MATCH_HEIGHT) * 3 + (MATCH_SPACING) * 3;
    }

    return 0;
};

const ROUND_CONFIG = [
    { key: 'r64', label: 'R64', mobileLabel: 'R64', title: 'Round of 64`' },
    { key: 'r32', label: 'R32', mobileLabel: 'R32', title: 'Round of 32' },
    { key: 'r16', label: 'R16', mobileLabel: 'R16', title: 'Round of 16' },
    { key: 'quarterfinals', label: 'Quarterfinals', mobileLabel: 'QF', title: 'Quarterfinals' },
    { key: 'semifinals', label: 'Semifinals', mobileLabel: 'SF', title: 'Semifinals' },
    { key: 'finals', label: 'Finals', mobileLabel: 'F', title: 'Finals' }
];


export const TennisBracket = ({
    matches = {},
}) => {
    // const categories = Object.keys(matches);
    const [localMatches, setLocalMatches] = React.useState(matches);
    const categories = Object.keys(localMatches);
    const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);
    const [resultDialogOpen, setResultDialogOpen] = React.useState(false);
    const [selectedMatch, setSelectedMatch] = React.useState(null);
    const [selectedMatchInfo, setSelectedMatchInfo] = React.useState(null);
    const [selectedCategory, setSelectedCategory] = React.useState(categories[0] || '');
    // const availableStages = React.useMemo(() =>
    //     selectedCategory ? Object.keys(matches[selectedCategory]) : [],
    //     [matches, selectedCategory]);
    const availableStages = React.useMemo(() =>
        selectedCategory ? Object.keys(localMatches[selectedCategory]) : [],
        [localMatches, selectedCategory]);

    const [selectedStage, setSelectedStage] = React.useState(availableStages[0] || '');

    // Use localMatches instead of matches for all our state calculations
    const handleLocalUpdate = (category, stage, round, matchIndex, updatedMatch) => {
        setLocalMatches(prevMatches => {
            // Create a deep copy of the matches structure
            const newMatches = JSON.parse(JSON.stringify(prevMatches));

            // Update the specific match
            newMatches[category][stage][round][matchIndex] = updatedMatch;

            return newMatches;
        });
    };

    // Get current matches based on selected category and stage
    const currentMatches = React.useMemo(() =>
        selectedCategory && selectedStage ? localMatches[selectedCategory][selectedStage] : {},
        [localMatches, selectedCategory, selectedStage]);

    // Get available rounds based on current matches
    const availableRounds = React.useMemo(() =>
        ROUND_CONFIG.filter(round =>
            currentMatches[round.key] && currentMatches[round.key].length > 0
        ),
        [currentMatches]);

    const [visibleRounds, setVisibleRounds] = React.useState(() =>
        availableRounds.slice(0, 3).map(round => round.key)
    );

    const [selectedRound, setSelectedRound] = React.useState(() =>
        availableRounds[1]?.label || availableRounds[0]?.label
    );

    const isDesktop = useResponsive('up', 'lg');

    if (!categories.length) {
        return <Typography>No tournament data available</Typography>;
    }

    if (!availableStages.length) {
        return <Typography>No stages available for selected category</Typography>;
    }

    if (!availableRounds.length) {
        return <Typography>No rounds available for selected stage</Typography>;
    }

    const handleRoundChange = (_, newRound) => {
        const roundIndex = availableRounds.findIndex(r => r.label === newRound);
        if (roundIndex === -1) return;

        let newVisibleRounds;
        if (roundIndex === 0) {
            newVisibleRounds = availableRounds.slice(0, 3).map(r => r.key);
        } else if (roundIndex === availableRounds.length - 1) {
            newVisibleRounds = availableRounds.slice(-3).map(r => r.key);
        } else {
            newVisibleRounds = availableRounds.slice(roundIndex - 1, roundIndex + 2).map(r => r.key);
        }

        setVisibleRounds(newVisibleRounds);
        setSelectedRound(newRound);
    };

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        // Reset stage to first available stage in new category
        const newStages = Object.keys(matches[newCategory]);
        setSelectedStage(newStages[0]);
        // Reset rounds based on new matches
        const newMatches = matches[newCategory][newStages[0]];
        const newAvailableRounds = ROUND_CONFIG.filter(round =>
            newMatches[round.key] && newMatches[round.key].length > 0
        );
        setVisibleRounds(newAvailableRounds.slice(0, 3).map(round => round.key));
        setSelectedRound(newAvailableRounds[1]?.label || newAvailableRounds[0]?.label);
    };

    // Handle stage change
    const handleStageChange = (event) => {
        const newStage = event.target.value;
        setSelectedStage(newStage);
        // Reset rounds based on new matches
        const newMatches = matches[selectedCategory][newStage];
        const newAvailableRounds = ROUND_CONFIG.filter(round =>
            newMatches[round.key] && newMatches[round.key].length > 0
        );
        setVisibleRounds(newAvailableRounds.slice(0, 3).map(round => round.key));
        setSelectedRound(newAvailableRounds[1]?.label || newAvailableRounds[0]?.label);
    };

    const renderPlayer = (player) => (
        <Player winner={player.winner}>
            <span>
                {player.name}
                {player.seed && <Seed>[{player.seed}]</Seed>}
            </span>
            <Score>
                {player.winner && <WinnerCheck />}
                {player.score?.map((s, i) => (  // Added optional chaining
                    <ScoreNumber key={i}>{s}</ScoreNumber>
                ))}
                {player.walkover && <span>W/O</span>}
            </Score>
        </Player>
    );

    // Update the schedule update handler
    const handleScheduleUpdate = (round, matchIndex, updates) => {
        if (!currentMatches || !round || matchIndex === undefined) {
            console.error('Missing required data for update');
            return;
        }

        const currentMatch = currentMatches[round]?.[matchIndex];
        if (!currentMatch) {
            console.error('Match not found');
            return;
        }

        const updatedMatch = {
            ...currentMatch,
            datetime: updates.dateTime,
            location: updates.venue
        };

        // Use the correct round key from selectedMatch
        handleLocalUpdate(
            selectedCategory,
            selectedStage,
            round,  // This is now the correct round key
            matchIndex,
            updatedMatch
        );

        setScheduleDialogOpen(false);
    };

    const handleResultSubmit = (round, matchIndex, result) => {
        // First, update the current match with the result
        const updatedMatch = {
            ...currentMatches[round][matchIndex],
            ...result
        };

        // Find the index of the current round in our configuration
        const roundIndex = ROUND_CONFIG.findIndex(r => r.key === round);
        const nextRound = ROUND_CONFIG[roundIndex + 1]?.key;
        const nextMatchIndex = Math.floor(matchIndex / 2);

        // Update the current match first
        handleLocalUpdate(
            selectedCategory,
            selectedStage,
            round,
            matchIndex,
            updatedMatch
        );

        // If there's a next round, we need to update the appropriate player slot
        if (nextRound && currentMatches[nextRound]) {
            const winner = result.player1.winner ? result.player1 : result.player2;

            // Get the existing next round match (or create a new one if it doesn't exist)
            const nextMatch = currentMatches[nextRound][nextMatchIndex] || {
                player1: { name: 'TBD' },
                player2: { name: 'TBD' }
            };

            // Determine if this winner should go to player1 or player2 slot
            // Even numbered matches (0, 2, 4...) fill player1 slots
            // Odd numbered matches (1, 3, 5...) fill player2 slots
            const isEvenMatch = matchIndex % 2 === 0;

            const updatedNextMatch = {
                ...nextMatch,
                [isEvenMatch ? 'player1' : 'player2']: {
                    name: winner.name,
                    seed: winner.seed  // Preserve seed information
                }
            };

            // Update the next round's match
            handleLocalUpdate(
                selectedCategory,
                selectedStage,
                nextRound,
                nextMatchIndex,
                updatedNextMatch
            );
        }

        setResultDialogOpen(false);
    };

    // Modify your renderMatch function
    const renderMatch = (match, round, matchIndex) => (
        <Match elevation={1}>
            <MatchContent>
                <DateTime
                    onClick={(e) => {
                        e.stopPropagation();
                        if (match) {
                            setSelectedMatch({ round, matchIndex });
                            setSelectedMatchInfo(match);
                            setScheduleDialogOpen(true);
                        }
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {/* Format the datetime string for display */}
                    {match.datetime ? dayjs(match.datetime).format('ddd, MMM D, h:mm A') : 'Time TBD'}
                    <Location>{match.location || 'Court TBD'}</Location>
                </DateTime>
                <Box
                    onClick={() => {
                        if (match) {
                            setSelectedMatch({ round, matchIndex });
                            setSelectedMatchInfo(match);
                            setResultDialogOpen(true);
                        }
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {renderPlayer(match.player1)}
                    {renderPlayer(match.player2)}
                </Box>
            </MatchContent>
        </Match>
    );

    // Get the rounds that should be displayed
    const getDisplayRounds = () => {
        return visibleRounds.map(roundKey => ({
            key: roundKey,
            title: ROUND_CONFIG.find(r => r.key === roundKey)?.title,
            matches: currentMatches[roundKey]
        }));
    };




    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                {isDesktop ? (
                    <Tabs
                        value={selectedRound}
                        onChange={handleRoundChange}
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#1976d2',
                            }
                        }}
                    >
                        {availableRounds.map(({ label, key }) => (
                            <Tab
                                key={label}
                                label={label}
                                value={label}
                                sx={{
                                    color: visibleRounds.includes(key)  // Changed from label.toLowerCase().replace(' ', '')
                                        ? '#1976d2'
                                        : 'text.primary',
                                    '&.Mui-selected': {
                                        color: '#1976d2',
                                    }
                                }}
                            />
                        ))}
                    </Tabs>
                ) : null}

                <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    size="small"
                >
                    {categories.map(category => (
                        <MenuItem key={category} value={category}>{category}</MenuItem>
                    ))}
                </Select>

                <Select
                    value={selectedStage}
                    onChange={handleStageChange}
                    size="small"
                >
                    {availableStages.map(stage => (
                        <MenuItem key={stage} value={stage}>{stage}</MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Mobile round switcher */}
            {!isDesktop && (
                <RoundSwitcher>
                    {availableRounds.map(({ label, mobileLabel }) => (
                        <RoundButton
                            key={label}
                            selected={selectedRound === label}
                            onClick={() => handleRoundChange(null, label)}
                            variant="outlined"
                        >
                            {mobileLabel}
                        </RoundButton>
                    ))}
                </RoundSwitcher>
            )}

            <BracketContainer>
                {(isDesktop ? getDisplayRounds() : [
                    {
                        key: ROUND_CONFIG.find(r => r.label === selectedRound)?.key,
                        title: ROUND_CONFIG.find(r => r.label === selectedRound)?.title,
                        matches: currentMatches[ROUND_CONFIG.find(r => r.label === selectedRound)?.key]
                    }
                ]).map((round, roundIndex) => (
                    <Round key={round.title} sx={{ minWidth: isDesktop ? '300px' : '100%' }}>
                        <RoundTitle
                            variant="h2"
                            className={isDesktop ?
                                (round.title === ROUND_CONFIG.find(r => r.label === selectedRound)?.title ? 'active' : '')
                                : 'active'
                            }
                        >
                            {round.title}
                        </RoundTitle>
                        {round.matches?.map((match, matchIndex) => (
                            <div
                                key={matchIndex}
                                style={{
                                    position: 'relative',
                                    marginTop: isDesktop ?
                                        calculateMatchOffset(roundIndex, matchIndex, round.matches.length)
                                        : 0
                                }}
                            >
                                {renderMatch(match, round.key, matchIndex)}  {/* Pass the round key here */}
                            </div>
                        ))}
                    </Round>
                ))}
            </BracketContainer>
            {selectedMatchInfo && (
                <>
                    <ScheduleDialog
                        open={scheduleDialogOpen}
                        onClose={() => {
                            setScheduleDialogOpen(false);
                            setSelectedMatchInfo(null);
                        }}
                        onSave={(updates) => {
                            handleScheduleUpdate(
                                selectedMatch.round,
                                selectedMatch.matchIndex,
                                updates
                            );
                        }}
                        currentDateTime={selectedMatchInfo.datetime}
                        currentVenue={selectedMatchInfo.location}
                    />

                    <ResultDialog
                        open={resultDialogOpen}
                        onClose={() => {
                            setResultDialogOpen(false);
                            setSelectedMatchInfo(null);
                        }}
                        onSave={(result) => {
                            handleResultSubmit(
                                selectedMatch.round,
                                selectedMatch.matchIndex,
                                result
                            );
                        }}
                        match={selectedMatchInfo}
                    />
                </>
            )}
        </>
    );
};