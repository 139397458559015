import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, Typography, OutlinedInput } from '@mui/material';

export const TournamentCategories = ({ tournamentMeta, selectedCategories, onChange }) => {
    const [selectableCategories, setSelectableCategories] = useState([]);

    useEffect(() => {
        if (selectedCategories.length === 0) {
            // If no categories are selected, all categories are selectable
            setSelectableCategories(tournamentMeta.categoriesList.map(cat => cat.name));
        } else {
            // Get the first selected category's metadata
            const selectedCategory = tournamentMeta.categoriesList.find(
                cat => cat.name === selectedCategories[0]
            );

            if (selectedCategory?.metadata?.selectableCategories) {
                // Get the names of selectable categories
                const selectableCategoryIds = selectedCategory.metadata.selectableCategories;
                const selectableCategoryNames = tournamentMeta.categoriesList
                    .filter(cat => selectableCategoryIds.includes(cat.id))
                    .map(cat => cat.name);

                setSelectableCategories([selectedCategory.name, ...selectableCategoryNames]);
            } else {
                // Fallback to all categories if no metadata
                setSelectableCategories(tournamentMeta.categoriesList.map(cat => cat.name));
            }
        }
    }, [selectedCategories, tournamentMeta]);

    const handleCategoryChange = (event) => {
        const value = event.target.value;
        // Ensure we don't exceed maxNumberOfCategories
        if (value.length <= (tournamentMeta.maxNumberOfCategories || 2)) {
            onChange(value);
        }
    };

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel>Categories (max {tournamentMeta.maxNumberOfCategories || 2})</InputLabel>
            <Select
                multiple
                value={selectedCategories}
                onChange={handleCategoryChange}
                input={<OutlinedInput label={`Categories (max ${tournamentMeta.maxNumberOfCategories || 2})`} />}
                renderValue={(selected) => selected.join(", ")}
                required
            >
                {tournamentMeta.categoriesList?.map((category) => (
                    <MenuItem
                        key={category.id}
                        value={category.name}
                        disabled={!selectableCategories.includes(category.name)}
                    >
                        <Checkbox checked={selectedCategories.indexOf(category.name) > -1} />
                        {category.name}
                    </MenuItem>
                ))}
            </Select>
            {/* {selectedCategories.length === 0 && (
                <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                    Select a category to see additional available options
                </Typography>
            )} */}
        </FormControl>
    );
};