import React, { useEffect, useState, useContext } from "react";
import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
    Avatar,
    Button,
    List,
    ListItem,
    ListItemText,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemIcon,
    OutlinedInput,
    CircularProgress,
    FormControlLabel,
    RadioGroup,
    Radio,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    useTheme,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import PolylineIcon from '@mui/icons-material/Polyline';
import EmailIcon from "@mui/icons-material/Email";
import { Link, useParams } from 'react-router-dom';
// Tab Icons
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CollectionsIcon from '@mui/icons-material/Collections';
import TimelineIcon from '@mui/icons-material/Timeline';
import { getTournamentById, createTournamentRegistrations, getUserByUserId, getRegistrationsByTournamentId, updateTournamentRegistrations } from "../api/ApiGateway";
import { getUserByEmailOrPhone } from '../utils/getUser';
import { formatDate, formatTime } from './TournamentRegistrationPage';
import { AuthContext } from '../utils/auth';
import { TennisBracket } from "./TournamentDraw";
// import { TennisBracket } from './TournamentDraw';
import { TournamentCategories } from './TournamentCategories';
import { VenueDisplay } from './VenueDisplay'

const DEFAULT_CONFIG = {
    doubles: false,
    isAcademyNameOptional: true,
    isCoachNameOptional: false,
    maxCategoriesPerPlayer: 2,
    defaultCurrency: 'INR',
    isPaymentGatewayEnabled: false  // Default to false
};

const isTournamentActive = (lastDateToRegister) => {
    if (!lastDateToRegister) return false;

    // Convert the ISO string to Date object
    const lastRegistrationDate = new Date(lastDateToRegister);

    // Get current date in IST
    const currentDate = new Date();
    const istOffset = 330; // IST offset is UTC+5:30 (330 minutes)
    const localOffset = currentDate.getTimezoneOffset();
    const totalOffset = istOffset + localOffset;

    // Adjust current time to IST
    const istCurrentDate = new Date(currentDate.getTime() + totalOffset * 60000);

    // Compare dates
    return istCurrentDate < lastRegistrationDate;
};

const calculateTotalPrice = (tournament, selectedCategories) => {
    if (!tournament?.categoriesList || !selectedCategories?.length) {
        return {
            amountInRupees: 0,
            amountInPaise: 0,
            currency: tournament?.config?.defaultCurrency || 'INR'
        };
    }

    const selectedCategoryDetails = tournament.categoriesList
        .filter(category => selectedCategories.includes(category.name));

    const breakdown = selectedCategoryDetails.map(category => {
        // First try to get amount from metadata
        if (category.metadata?.tournament?.fee) {
            return {
                categoryId: category.id || category.name,
                categoryName: category.name,
                amount: category.metadata.tournament.fee.amount,
                currency: category.metadata.tournament.fee.currency
            };
        }

        // Fallback to parsing from subtext
        const priceText = category.subtext.find(text => text.startsWith('INR'));
        const amount = priceText ? parseInt(priceText.replace('INR', '').trim(), 10) : 0;

        return {
            categoryId: category.id || category.name,
            categoryName: category.name,
            amount,
            currency: 'INR'
        };
    });

    const totalAmount = breakdown.reduce((sum, category) => sum + category.amount, 0);

    return {
        amountInRupees: totalAmount,
        amountInPaise: totalAmount * 100,
        currency: tournament?.config?.defaultCurrency || 'INR',
        breakdown
    };
};

const RegistrationSummaryDialog = ({ open, onClose, registrationData, onProceedToPayment }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Registration Summary</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Name"
                            secondary={registrationData?.name}
                        />
                    </ListItem>
                    {registrationData?.teamMateName && (
                        <ListItem>
                            <ListItemText
                                primary="Team Mate"
                                secondary={registrationData?.teamMateName}
                            />
                        </ListItem>
                    )}
                    <ListItem>
                        <ListItemText
                            primary="Selected Events"
                            secondary={registrationData?.selectedCategories?.join(", ")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Amount to Pay"
                            secondary={`₹${registrationData?.amount}`}
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">Cancel</Button>
                <Button
                    onClick={onProceedToPayment}
                    variant="contained"
                    color="primary"
                >
                    Pay Now
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const TournamentPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { tournamentId } = useParams();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [tournamentMeta, setTournamentMeta] = useState({});
    const [tournamentRegistrations, setTournamentRegistrations] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [user, setUser] = useState({});
    const [loggedInUserId, setLoggedInUserId] = useState('');
    const [filteredCategory, setFilteredCategory] = useState("All");
    const [isLoggedInUserTournamentAdmin, setIsLoggedInUserTournamentAdmin] = useState(false);

    // Form input states
    const [name, setName] = useState('');
    const [teamMateName, setTeamMateName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [coachName, setCoachName] = useState('');
    const [academyName, setAcademyName] = useState('');
    const [gender, setGender] = useState('Male'); // Default value is 'Male'

    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(true);
    const [isCoachNameValid, setIsCoachNameValid] = useState(true);
    const [isAcademyNameValid, setIsAcademyNameValid] = useState(true);
    const [isCategoriesValid, setIsCategoriesValid] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [maxNumberOfCategories, setMaxNumberOfCategories] = useState(2);
    const [matches, setMatches] = useState({});

    const [showPaymentSummary, setShowPaymentSummary] = useState(false);
    const [registrationData, setRegistrationData] = useState(null);

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.length > maxNumberOfCategories) {
            return;
        }
        setSelectedCategories(typeof value === "string" ? value.split(",") : value);
    };

    const initializePayment = () => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID_DEV,
            amount: registrationData.amountInPaise,  // amount in paise
            currency: registrationData.currency,
            name: tournamentMeta.tournamentName,
            description: `Registration for ${registrationData.selectedCategories.join(", ")}`,
            handler(response) {
                completeRegistration({
                    ...registrationData,
                    paymentId: response.razorpay_payment_id
                });
            },
            prefill: {
                name: registrationData.name,
                email: registrationData.userMeta.email,
                contact: registrationData.userMeta.phone
            },
            theme: {
                color: "#1976d2"
            }
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    // Add Razorpay script on component mount
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Complete registration after successful payment
    const completeRegistration = async (data) => {
        try {
            setIsLoading(true);

            // Make registration API call with payment info
            await createTournamentRegistrations({
                ...data,
                paymentStatus: 'completed'
            });

            // Close the payment summary dialog first
            setShowPaymentSummary(false);

            // Then reset form and show success dialog
            resetForm();
            setIsDialogOpen(true);

        } catch (error) {
            console.error('Error during registration', error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setName('');
        setTeamMateName('');
        setPhone('');
        setEmail('');
        setDateOfBirth('');
        setCoachName('');
        setAcademyName('');
        setSelectedCategories([]);
        setGender('Male');
    };

    const updateRegistrationStatus = async (recordId, tournamentId, newStatus) => {
        try {
            setIsLoading(true);

            const data = {
                id: recordId,
                attributes: {
                    tournamentId,
                    registrationStatus: newStatus
                }
            };

            await updateTournamentRegistrations(data);

            // Update the local state
            setTournamentRegistrations(prevRegistrations =>
                prevRegistrations.map(registration =>
                    registration.id === recordId
                        ? { ...registration, registrationStatus: newStatus }
                        : registration
                )
            );

            // Switch to the Participants tab
            setSelectedTab(1);

            setIsLoading(false);
        } catch (error) {
            console.error(`Error updating registration status to ${newStatus}`, error);
            setIsLoading(false);
        }
    };
    const approveTournamentRegistration = (recordId, tournamentId) => {
        updateRegistrationStatus(recordId, tournamentId, 'approved');
    };

    const waitlistTournamentRegistration = (recordId, tournamentId) => {
        updateRegistrationStatus(recordId, tournamentId, 'pending');
    };


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const getStatusChipColor = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'warning';
            case 'approved':
                return 'success';
            case 'rejected':
                return 'error';
            default:
                return 'default';
        }
    };

    const filteredAndSortedRegistrations = tournamentRegistrations
        .filter(registration => {
            const isIncluded = filteredCategory === "All" || registration.userMeta.selectedCategories.includes(filteredCategory);
            return isIncluded;
        })
        .map(registration => {
            const dateTimeString = `${registration.registrationDate} ${registration.registrationTime.replace('a.m', 'AM').replace('p.m', 'PM')}`;
            const combined = new Date(dateTimeString)
            return {
                ...registration,
                combinedDateTime: combined
            };
        })
        .sort((a, b) => {
            const diff = b.combinedDateTime - a.combinedDateTime;
            return diff;
        });

    filteredAndSortedRegistrations.forEach((reg, index) => {
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const tournamentMetaResponse = await getTournamentById(tournamentId);
                if (tournamentMetaResponse.status !== 200) {
                    console.error("Failed to fetch tournament meta data");
                    return;
                }

                // Merge default config with tournament config
                const mergedConfig = {
                    ...DEFAULT_CONFIG,
                    ...(tournamentMetaResponse.data.config || {})
                };

                setTournamentMeta({
                    ...tournamentMetaResponse.data,
                    config: mergedConfig
                });

                if (tournamentMetaResponse.data.maxNumberOfCategories) {
                    setMaxNumberOfCategories(tournamentMetaResponse.data.maxNumberOfCategories);
                }

                if (tournamentMetaResponse.data.matches) { setMatches(tournamentMetaResponse.data.matches) }

                const active = isTournamentActive(tournamentMetaResponse.data.lastDateToRegister);
                setIsActive(active);
                const registrationsResponse = await getRegistrationsByTournamentId(tournamentId);
                setTournamentRegistrations(registrationsResponse.data);
                try {
                    const userId = await getUserByEmailOrPhone(currentUser);
                    setLoggedInUserId(userId);
                    const userData = await getUserByUserId(userId);
                    if (userId && tournamentMetaResponse.data) {
                        const isHost = tournamentMetaResponse.data.hosts.find((host) => host.id === userId);
                        if (isHost) {
                            setIsLoggedInUserTournamentAdmin(true);
                        }
                        console.log('is host', isHost)
                    }
                    console.log(userData.data)
                    setUser(userData.data);
                }
                catch (error) {
                    console.error(error);
                }
                setIsLoading(false);
            }
            catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [tournamentId]);

    const registerUser = async (event) => {
        try {
            setIsNameValid(true);
            setIsPhoneValid(true);
            setIsEmailValid(true);
            setIsDateOfBirthValid(true);
            setIsCategoriesValid(true);
            setIsCoachNameValid(true);
            setIsAcademyNameValid(true);

            // Regex for validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const phoneRegex = /^\d{10}$/;
            const dateOfBirthRegex = /^\d{4}-\d{2}-\d{2}$/;

            // Validation checks
            if (!name) {
                setIsNameValid(false);
                return;
            }

            if (!teamMateName && tournamentMeta.config.doubles) {
                setIsNameValid(false);
                return;
            }

            if (!phone || !phoneRegex.test(phone)) {
                setIsPhoneValid(false);
                return;
            }

            if (!email || !emailRegex.test(email)) {
                setIsEmailValid(false);
                return;
            }

            if (!dateOfBirth || !dateOfBirthRegex.test(dateOfBirth)) {
                setIsDateOfBirthValid(false);
                return;
            }

            if (selectedCategories.length === 0) {
                setIsCategoriesValid(false);
                return;
            }

            // Only validate if these fields are not optional
            if (!coachName && tournamentMeta.config.isCoachNameOptional === false) {
                setIsCoachNameValid(false);
                return;
            }

            if (!academyName && tournamentMeta.config.isAcademyNameOptional === false) {
                setIsAcademyNameValid(false);
                return;
            }

            setIsLoading(true);
            const currentDate = new Date();
            const currentISTDate = currentDate.toLocaleString("en-CA", { timeZone: "Asia/Kolkata" });

            const priceDetails = calculateTotalPrice(tournamentMeta, selectedCategories);

            // Construct registration data
            const regData = {
                tournamentId,
                id: uuidv4(),
                registrationStatus: 'pending',
                registrationDate: currentISTDate.split(',')[0],
                registrationTime: currentISTDate.split(',')[1],
                registrationTimeStamp: currentDate.getTime(),
                amount: priceDetails.amountInRupees,
                amountInPaise: priceDetails.amountInPaise,
                currency: priceDetails.currency,
                categoryBreakdown: priceDetails.breakdown,
                userMeta: {
                    name,
                    phone,
                    email,
                    dateOfBirth,
                    gender,
                    coachName,
                    academyName,
                    selectedCategories,
                    teamMateName
                }
            };

            if (user) {
                regData.userId = loggedInUserId;
                regData.userName = user.displayName;
                regData.userEmail = user.email;
                regData.userPhoneNumber = user.phoneNumber;
            }

            if (tournamentMeta.config.isPaymentGatewayEnabled) {
                // For payment gateway enabled tournaments, show payment summary
                setRegistrationData({
                    ...regData,
                    name: regData.userMeta.name,
                    teamMateName: regData.userMeta.teamMateName,
                    selectedCategories: regData.userMeta.selectedCategories,
                    amount: regData.amount,
                    amountInPaise: regData.amountInPaise,
                    currency: regData.currency
                });
                setShowPaymentSummary(true);
            } else {
                // For non-payment gateway tournaments, directly register
                await createTournamentRegistrations(regData);
                resetForm();
                setIsDialogOpen(true);
            }

            setIsLoading(false);
        } catch (error) {
            console.error('Error during registration', error);
            setIsLoading(false);
        }
    };


    // When the page loads, check if the tab index is stored in localStorage
    useEffect(() => {
        const storedTab = localStorage.getItem('selectedTab');
        if (storedTab) {
            setSelectedTab(parseInt(storedTab, 10));
            // Clear the tab from localStorage so it doesn't persist unnecessarily
            localStorage.removeItem('selectedTab');
        }
    }, []);

    const ParticipantsList = ({ registrations }) => {
        return (
            <Box>
                {registrations.map((registration, index) => (
                    <Accordion key={registration.id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography>{registration.userMeta.name}{tournamentMeta.config.doubles ? `, ${registration.userMeta.teamMateName}` : ''}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <strong>Registered At:</strong> {registration.registrationDate} {registration.registrationTime}
                            </Typography>
                            <Typography>
                                <strong>Categories:</strong> {registration.userMeta.selectedCategories.join(", ")}
                            </Typography>
                            <Typography>
                                <strong>Registration status:</strong> <Chip
                                    label={registration.registrationStatus}
                                    color={getStatusChipColor(registration.registrationStatus)}
                                />
                            </Typography>

                            {isLoggedInUserTournamentAdmin &&
                                <>
                                    <Typography>
                                        <strong>Phone:</strong> {registration.userMeta.phone}
                                    </Typography>
                                    <Typography>
                                        <strong>Admin approval:</strong> {registration.registrationStatus !== 'approved' && (
                                            <Button
                                                id="new-session-button"
                                                variant="contained"
                                                onClick={() => approveTournamentRegistration(registration.id, registration.tournamentId)}
                                                sx={{ bgcolor: '#229A16' }}
                                            >
                                                Approve
                                            </Button>
                                        )}
                                        {registration.registrationStatus === 'approved' && (
                                            <Button
                                                id="new-session-button"
                                                variant="contained"
                                                onClick={() => waitlistTournamentRegistration(registration.id, registration.tournamentId)}
                                                sx={{ bgcolor: 'primary' }}
                                            >
                                                Waitlist
                                            </Button>
                                        )}
                                    </Typography></>}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        );
    };

    const showCoachNameValidation = !isCoachNameValid &&
        tournamentMeta.config.isCoachNameOptional === false;

    const showAcademyNameValidation = !isAcademyNameValid &&
        tournamentMeta.config.isAcademyNameOptional === false;

    return (
        <>
            <Helmet>
                <title> {`Playgrade Tournament | ${tournamentMeta.tournamentName}`}</title>
                <meta name="robots" content="index, follow" />
                {/* Add these SEO meta tags */}
                <meta name="description" content={tournamentMeta.information} />
                <meta name="keywords" content={`tennis tournament,tennis league,tennis competition,tennis,${tournamentMeta.addressCityCountry}`} />
                <link rel="canonical" href={`https://playgrade.app/v2/tournaments/${tournamentId}/register`} />
            </Helmet>

            <Container>
                {isLoading ? (
                    // Render loading indicator
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <Box sx={{ padding: 2 }}>
                        <RegistrationSummaryDialog
                            open={showPaymentSummary}
                            onClose={() => setShowPaymentSummary(false)}
                            registrationData={registrationData}
                            onProceedToPayment={initializePayment}
                        />

                        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                            <DialogTitle>Registration Successful</DialogTitle>
                            <DialogContent>
                                <Typography>Your registration has been successfully completed!</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        localStorage.setItem('selectedTab', '1');
                                        window.location.reload();
                                    }}
                                    color="primary"
                                >
                                    View Registrations
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* Header */}
                        <Card sx={{ mb: 2 }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Grid container justifyContent="center"> {/* Centers the content horizontally */}
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            textAlign="center"
                                            sx={{
                                                wordWrap: "break-word",
                                                whiteSpace: "normal",
                                                overflowWrap: "break-word"
                                            }}
                                        >
                                            {tournamentMeta.tournamentName}
                                        </Typography>
                                        <Typography variant="body2" textAlign="center"> {/* Text centered */}
                                            {formatDate(tournamentMeta.startDateTime)} - {formatDate(tournamentMeta.endDateTime)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {/* Tabs with Icons */}
                        <Card sx={{ mb: 2 }}>
                            <Tabs value={selectedTab} onChange={handleTabChange} centered>
                                <Tab icon={<HomeIcon />} label="Home" />
                                <Tab icon={<GroupIcon />} label="Participants" />
                                <Tab icon={<PolylineIcon />} label="Draws & Results" />
                                {/* <Tab icon={<LeaderboardIcon />} label="Standings" />
                                    <Tab icon={<TimelineIcon />} label="Statistics" />
                                    <Tab icon={<CollectionsIcon />} label="Gallery" /> */}
                            </Tabs>
                        </Card>

                        {/* Content based on selected Tab */}
                        {selectedTab === 0 &&
                            <>{/* Information Section */}
                                <Card sx={{ mb: 2 }}>
                                    <CardContent sx={{ padding: 2 }}>
                                        <Typography variant="h6">Information</Typography>
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            {tournamentMeta.information}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <Grid container spacing={2}>
                                    {/* Registration Section */}
                                    <Grid item xs={12} md={6}>
                                        <Card sx={{ mb: 2 }}>
                                            <CardContent sx={{ padding: 2 }}>
                                                <Typography variant="h6">Events</Typography>
                                                <List>
                                                    {tournamentMeta.categoriesList?.map((category, index) => (
                                                        <ListItem key={index} alignItems="flex-start">
                                                            <ListItemIcon>
                                                                <SportsTennisIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={category.name}
                                                                secondary={category.subtext.join(" • ")}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </CardContent>
                                        </Card>

                                        {/* Payment Information */}
                                        {!tournamentMeta?.config?.isPaymentGatewayEnabled && <Card sx={{ mb: 2 }}>
                                            <CardContent sx={{ padding: 2 }}>
                                                <Typography variant="h6">Payment Information</Typography>
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <AttachMoneyIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span>UPI Id: {tournamentMeta.upiId}</span>

                                                                    <a href={`phonepe://upi/pay?pa=${tournamentMeta.upiId}&cu=INR&tn=${tournamentMeta.tournamentName}`} style={{ marginLeft: '10px' }}>
                                                                        <img
                                                                            src="https://www.phonepe.com/webstatic/8196/static/PhonePe_vertical-16158be8710408f3561e1d07d01d5d89.png"
                                                                            alt="Pay with PhonePe"
                                                                            style={{ width: '60px', cursor: 'pointer' }}  // Adjusted size
                                                                        />
                                                                    </a>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                </List>
                                                <Typography variant="body2" sx={{ mt: 1 }}>
                                                    Please complete the payment via UPI and ensure to use the correct UPI ID.
                                                    After payment, submit the payment confirmation to the event organizer.
                                                </Typography>
                                            </CardContent>
                                        </Card>}
                                        {tournamentMeta?.config?.isPaymentGatewayEnabled && <Card sx={{ mb: 2 }}>
                                            <CardContent sx={{ padding: 2 }}>
                                                <Typography variant="h6">Payment Information</Typography>
                                                {/* <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <AttachMoneyIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span>UPI Id: {tournamentMeta.upiId}</span>

                                                                    <a href={`phonepe://upi/pay?pa=${tournamentMeta.upiId}&cu=INR&tn=${tournamentMeta.tournamentName}`} style={{ marginLeft: '10px' }}>
                                                                        <img
                                                                            src="https://www.phonepe.com/webstatic/8196/static/PhonePe_vertical-16158be8710408f3561e1d07d01d5d89.png"
                                                                            alt="Pay with PhonePe"
                                                                            style={{ width: '60px', cursor: 'pointer' }}  // Adjusted size
                                                                        />
                                                                    </a>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                </List> */}
                                                <Typography variant="body2" sx={{ mt: 1 }}>
                                                    Please complete the payment after submitting the form to confirm your registration. The registration will not be considered complete until the payment is made. The registration fee is non-refundable.
                                                </Typography>
                                            </CardContent>
                                        </Card>}

                                        {/* Contact Information */}
                                        <Card sx={{ mb: 2 }}>
                                            <CardContent sx={{ padding: 2 }}>
                                                <Typography variant="h6">Tournament Information</Typography>
                                                <List>
                                                    {tournamentMeta.venues ? (
                                                        <VenueDisplay venues={tournamentMeta.venues} />
                                                    ) : (
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <LocationOnIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={<a href={`https://maps.google.com/?q=${tournamentMeta.addressName}`} target="_blank" rel="noopener noreferrer">
                                                                    {tournamentMeta.addressName}
                                                                </a>}
                                                            />
                                                        </ListItem>
                                                    )}
                                                    {tournamentMeta.contactPhoneNumber && (
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <PhoneIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <>
                                                                        <a href={`tel:${tournamentMeta.contactPhoneNumber}`}>{tournamentMeta.contactPhoneNumber}</a>
                                                                        {" - "}
                                                                        {tournamentMeta.contactPersonName}
                                                                    </>
                                                                }
                                                            />
                                                        </ListItem>
                                                    )}
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <CalendarTodayIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={<span><b>Registration closes</b>: {`${formatDate(tournamentMeta.lastDateToRegister)} ${formatTime(tournamentMeta.lastDateToRegister)}`}</span>}
                                                        />
                                                    </ListItem>
                                                    {/* <ListItem>
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="ysyugeee@gmail.com" />
                                    </ListItem> */}
                                                </List>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    {/* Sports and Events */}
                                    <Grid item xs={12} md={6}>
                                        <Card sx={{ mb: 2 }}>
                                            <CardContent sx={{ padding: 2 }}>
                                                <Typography variant="h6">Registration Form</Typography>
                                                {isActive && <Grid container spacing={2}>
                                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Name"
                                                            variant="outlined"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                        {!isNameValid && (
                                                            <Typography variant="caption" color="error"
                                                                sx={{ ml: 1 }} // Add margin top
                                                            >
                                                                Name is required
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    {tournamentMeta.config.doubles &&
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                fullWidth
                                                                label="Teammate Name"
                                                                variant="outlined"
                                                                value={teamMateName}
                                                                onChange={(e) => setTeamMateName(e.target.value)}
                                                                required
                                                            />
                                                            {!isNameValid && (
                                                                <Typography variant="caption" color="error"
                                                                    sx={{ ml: 1 }} // Add margin top
                                                                >
                                                                    Name is required
                                                                </Typography>
                                                            )}
                                                        </Grid>}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Phone"
                                                            variant="outlined"
                                                            value={phone}
                                                            onChange={(e) => {
                                                                setPhone(e.target.value);
                                                                setIsPhoneValid(true); // Remove error message when typing starts
                                                            }}
                                                            required
                                                            error={!isPhoneValid} // Error state for TextField
                                                            helperText={!isPhoneValid ? "Phone must be exactly 10 digits" : ""} // Error message
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Email"
                                                            variant="outlined"
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                                setIsEmailValid(true); // Remove error message when typing starts
                                                            }}
                                                            required
                                                            error={!isEmailValid} // Error state for TextField
                                                            helperText={!isEmailValid ? "Please enter a valid email address" : ""} // Error message
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Date of Birth (YYYY-MM-DD)"
                                                            variant="outlined"
                                                            value={dateOfBirth}
                                                            onChange={(e) => {
                                                                setDateOfBirth(e.target.value);
                                                                setIsDateOfBirthValid(true); // Remove error message when typing starts
                                                            }}
                                                            required
                                                            error={!isDateOfBirthValid} // Set error state for TextField
                                                            helperText={!isDateOfBirthValid ? "Please enter a valid date in YYYY-MM-DD format" : ""} // Error message
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TournamentCategories
                                                            tournamentMeta={tournamentMeta}
                                                            selectedCategories={selectedCategories}
                                                            onChange={setSelectedCategories}
                                                        />
                                                        {!isCategoriesValid && (
                                                            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                                                                Select at least one category
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <Typography>Gender</Typography>
                                                            <RadioGroup
                                                                row
                                                                value={gender}
                                                                onChange={(e) => setGender(e.target.value)}
                                                                required
                                                            >
                                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Coach Name${tournamentMeta.config.isCoachNameOptional ? ' (Optional)' : ''}`}
                                                            variant="outlined"
                                                            value={coachName}
                                                            onChange={(e) => setCoachName(e.target.value)}
                                                            required={!tournamentMeta.config.isCoachNameOptional}
                                                        />
                                                        {showCoachNameValidation && (
                                                            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                                                                Coach Name is required
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <TextField
                                                            fullWidth
                                                            label={`Academy Name${tournamentMeta.config.isAcademyNameOptional ? ' (Optional)' : ''}`}
                                                            variant="outlined"
                                                            value={academyName}
                                                            onChange={(e) => setAcademyName(e.target.value)}
                                                            required={!tournamentMeta.config.isAcademyNameOptional}
                                                        />
                                                        {showAcademyNameValidation && (
                                                            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                                                                Academy Name is required
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            fullWidth
                                                            onClick={registerUser}
                                                            disabled={isLoading} // Disable button if loading
                                                        >
                                                            {isLoading ? (
                                                                <CircularProgress size={24} /> // Show loader
                                                            ) : (
                                                                "Register"
                                                            )}
                                                        </Button>
                                                    </Grid>
                                                </Grid>}
                                                {!isActive && <>
                                                    <Typography
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Registration for this tournament is now closed.
                                                    </Typography>
                                                </>}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>


                            </>}

                        {/* Other Tab Content */}
                        {selectedTab === 1 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Participants ({filteredAndSortedRegistrations.length})</Typography>
                                            <Typography variant="subtitle2" gutterBottom>Filter by Category</Typography>
                                            <FormControl fullWidth sx={{ mb: 2 }}>
                                                <Select
                                                    value={filteredCategory}
                                                    onChange={(e) => setFilteredCategory(e.target.value)}
                                                >
                                                    <MenuItem value="All">All Categories</MenuItem>
                                                    {tournamentMeta.categoriesList?.map((category) => (
                                                        <MenuItem key={category.name} value={category.name}>{category.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {isMobile ? (
                                                <ParticipantsList registrations={filteredAndSortedRegistrations} />
                                            ) : (
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell>Categories</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Registered At</TableCell>
                                                                {isLoggedInUserTournamentAdmin && <TableCell>Phone</TableCell>}
                                                                {isLoggedInUserTournamentAdmin && <TableCell>Actions</TableCell>}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filteredAndSortedRegistrations.map((registration) => (
                                                                <TableRow key={registration.id}>
                                                                    <TableCell>
                                                                        {registration.userMeta.name}{tournamentMeta.config.doubles ? `, ${registration.userMeta.teamMateName}` : ''}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {registration.userMeta.selectedCategories.join(", ")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Chip
                                                                            label={registration.registrationStatus}
                                                                            color={getStatusChipColor(registration.registrationStatus)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {`${registration.registrationDate} ${registration.registrationTime}`}
                                                                    </TableCell>
                                                                    {isLoggedInUserTournamentAdmin &&
                                                                        <>
                                                                            <TableCell>
                                                                                {registration.userMeta.phone}
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                {registration.registrationStatus !== 'approved' && (
                                                                                    <Button
                                                                                        id="new-session-button"
                                                                                        variant="contained"
                                                                                        onClick={() => approveTournamentRegistration(registration.id, registration.tournamentId)}
                                                                                        sx={{ bgcolor: '#229A16' }}
                                                                                    >
                                                                                        Approve
                                                                                    </Button>
                                                                                )}
                                                                                {registration.registrationStatus === 'approved' && (
                                                                                    <Button
                                                                                        id="new-session-button"
                                                                                        variant="contained"
                                                                                        onClick={() => waitlistTournamentRegistration(registration.id, registration.tournamentId)}
                                                                                        sx={{ bgcolor: 'primary' }}
                                                                                    >
                                                                                        Waitlist
                                                                                    </Button>
                                                                                )}
                                                                            </TableCell></>}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        )}

                        {selectedTab === 2 && <TennisBracket
                            matches={matches}
                        />}
                        {/* {selectedTab === 3 && <Typography>Standings tab content</Typography>}
            {selectedTab === 4 && <Typography>Statistics tab content</Typography>}
            {selectedTab === 5 && <Typography>Gallery tab content</Typography>} */}
                    </Box>
                )}
            </Container >
        </>
    );
};

export default TournamentPage;