import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    TableBody,
    TableCell,
    TableRow,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Chip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../utils/auth';
import { getUserByEmailOrPhone } from '../utils/getUser';
import { getAllTournaments, getTournamentRegistrationsByUserId } from '../api/ApiGateway';
import { formatDate } from './TournamentRegistrationPage';
import { UserListHead } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';

const REGISTRATION_TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'categories', label: 'Categories', alignRight: false },
    { id: 'registrationDate', label: 'Registration Date', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'locationCityCountry', label: 'City', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function TournamentListPage() {
    const [page, setPage] = useState(0);
    const [registrationsPage, setRegistrationsPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [registrationsRowsPerPage, setRegistrationsRowsPerPage] = useState(5);
    const [tournamentList, setTournamentList] = useState([]);
    const [registrationsList, setRegistrationsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log('Starting data fetch...');
                const userId = await getUserByEmailOrPhone(currentUser);
                const [tournamentsResponse, registrationsResponse] = await Promise.all([
                    getAllTournaments(),
                    getTournamentRegistrationsByUserId(userId)
                ]);

                console.log('Tournaments Response:', tournamentsResponse);
                console.log('Registrations Response:', registrationsResponse);

                setTournamentList(tournamentsResponse.data);

                // Check what we're getting in the response
                console.log('Raw registrations response body:', registrationsResponse.data);
                const parsedRegistrations = registrationsResponse.data;
                console.log('Parsed registrations:', parsedRegistrations);

                setRegistrationsList(parsedRegistrations.registrations);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRegistrationsPage = (event, newPage) => {
        setRegistrationsPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangeRegistrationsRowsPerPage = (event) => {
        setRegistrationsPage(0);
        setRegistrationsRowsPerPage(parseInt(event.target.value, 10));
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'warning';
            case 'approved':
                return 'success';
            case 'rejected':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <>
            <Helmet>
                <title> Tournaments | Playgrade </title>
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Tennis Tournaments in cities across India hosted by Tennis clubs and organizations" />
                <link rel="canonical" href="https://playgrade.app/v2/tournaments" />
            </Helmet>

            <Container>
                {isLoading ? (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 'calc(100vh - 64px)',
                    }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {/* My Registrations Section */}
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                My Registrations
                            </Typography>
                        </Stack>

                        <Card sx={{ mb: 5 }}>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={REGISTRATION_TABLE_HEAD}
                                            rowCount={registrationsList.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            showCheckBox={false}
                                        />
                                        <TableBody>
                                            {registrationsList
                                                .slice(registrationsPage * registrationsRowsPerPage,
                                                    registrationsPage * registrationsRowsPerPage + registrationsRowsPerPage)
                                                .map((registration) => {
                                                    const tournament = tournamentList.find(t => t.id === registration.tournamentId);
                                                    return (
                                                        <TableRow hover key={registration.id} tabIndex={-1}>
                                                            <TableCell align="left">
                                                                {tournament?.tournamentName || 'Tournament Not Found'}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {registration.userMeta.selectedCategories.join(', ')}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {`${registration.registrationDate} ${registration.registrationTime}`}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Chip
                                                                    label={registration.registrationStatus}
                                                                    color={getStatusColor(registration.registrationStatus)}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Button
                                                                    variant="contained"
                                                                    component={Link}
                                                                    to={`/v2/tournaments/${registration.tournamentId}/register`}
                                                                >
                                                                    View
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={registrationsList.length}
                                rowsPerPage={registrationsRowsPerPage}
                                page={registrationsPage}
                                onPageChange={handleChangeRegistrationsPage}
                                onRowsPerPageChange={handleChangeRegistrationsRowsPerPage}
                            />
                        </Card>

                        {/* Available Tournaments Section */}
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Available Tournaments
                            </Typography>
                        </Stack>

                        <Card>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={tournamentList.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            showCheckBox={false}
                                        />
                                        <TableBody>
                                            {tournamentList
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    const { tournamentName, courtName, startDateTime, id, addressCityCountry } = row;
                                                    return (
                                                        <TableRow hover key={id} tabIndex={-1}>
                                                            <TableCell align="left">
                                                                {tournamentName}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {addressCityCountry}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {formatDate(startDateTime)}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Button
                                                                    variant="contained"
                                                                    component={Link}
                                                                    to={`/v2/tournaments/${id}/register`}
                                                                >
                                                                    View
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={tournamentList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card>
                    </>
                )}
            </Container>
        </>
    );
}