import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Chip, Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const VenueDisplay = ({ venues }) => {
    if (!venues || venues.length === 0) return null;

    return (
        <>
            {venues.map((venue, index) => (
                <ListItem
                    key={index}
                    alignItems="flex-start"
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 1,
                        pb: 2
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <ListItemIcon sx={{ minWidth: 40 }}>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <a
                                    href={`https://maps.google.com/?q=${venue.name}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'medium' }}>
                                        {venue.name}
                                    </Typography>
                                </a>
                            }
                        />
                    </Box>

                    <Box sx={{ pl: 5, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {venue.events.map((event, eventIndex) => (
                            <Chip
                                key={eventIndex}
                                label={event}
                                size="small"
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}
                            />
                        ))}
                    </Box>
                </ListItem>
            ))}
        </>
    );
};